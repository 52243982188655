import { useNavigate } from "react-router-dom";
import { pages } from "../navigation/navigator";

interface ValidationHeaderInterface {
  content: any;
  nextform: pages;
  onUnlock:()=>void
}
export const ValidationHeader: React.FC<ValidationHeaderInterface> = ({
  content,
  nextform,
  onUnlock
}) => {
  const navigate = useNavigate();
  return (
    <form
      method="POST"
      onSubmit={(e) => {
        e.preventDefault();
        onUnlock();
        if (nextform === pages.page2) {
          navigate("/step-2");
        }
        else if(nextform === pages.page3){
            navigate("/step-3");
        }
        else if(nextform===pages.page4){
            navigate("/step-4");
        }
        else if(nextform===pages.page5){
            navigate("/step-5");
        }
        else if(nextform===pages.page6){
           // navigate("/step-6");
        }
      }}
    >
      {content}
    </form>
  );
};
