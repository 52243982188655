import { ChangeEvent, useEffect, useState } from "react";
import { CustomInput, InputTurnus } from "../input/Custominput";
import { PaymentBlock } from "../input/PaymentBlock";
import { paymentInterval } from "../interface";
import { Navigate, useNavigate } from "react-router-dom";
import { pages, NavigationHeader } from "../navigation/navigator";
import { ValidationHeader } from "../validation/validate";
import { activatedPages } from "../App";
import { handlecouponsubmit } from "../api/send";
import { response } from "express";
interface Page1Interace {
  kaution: number;
  paymentInterval: paymentInterval;
  couponCode: string;
  insuranceNumber: string;
  referal: string;
  onUnlock: () => void;
  unlockedPages: activatedPages;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}
export const Page1: React.FC<Page1Interace> = ({
  kaution,
  paymentInterval,
  couponCode,
  referal,
  unlockedPages,
  insuranceNumber,
  onUnlock,
  handleInputChange,
  handleSelectChange,
}) => {
  const [inputValues, setInputValues] = useState({
    kaution: "",
  });
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();
  const [couponResponseMessage, setCouponResponseMessage] = useState<{
    message: string;
    status: Number;
    percentage: Number;
  }>();
  const navigation = useNavigate();
  async function checkFormInput(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setInputValues((inputValues) => ({
      ...inputValues,
      [event.target.name]: isNaN(parseInt(event.target.value))
        ? ""
        : event.target.value,
    }));
  }
  const createCustomChangeEvent = (
    value: string,
    name: string
  ): ChangeEvent<HTMLInputElement> => {
    return {
      target: {
        value: value,
        name: name,
      },
    } as ChangeEvent<HTMLInputElement>;
  };
  const checkcoupon = (event: ChangeEvent<HTMLInputElement>) => {
    let couponValue = event.target.value;
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    let timeout = setTimeout(
      () =>
        handlecouponsubmit(couponValue).then((response: any) => {
          setCouponResponseMessage({
            message: response?.message,
            status: response?.status ?? 200,
            percentage: parseInt(response?.percentage) ?? 0,
          });
        }),
      1000
    );
    setTimeOutId(timeout);
  };
  useEffect(() => {
    if (couponCode.length === 0) {
    }
    else {
      checkcoupon(createCustomChangeEvent(couponCode, "couponCode"));
    }
  }, [couponCode]);
  return (
    <ValidationHeader
      nextform={pages.page2}
      onUnlock={onUnlock}
      content={
        <div className="ekform-container">
          <NavigationHeader unlockedPages={unlockedPages}></NavigationHeader>
          <div id="section1">
            <div className="section">
              <h2>Berechnung Ihres Beitrags</h2>
              <p>Der Gesetzgeber schreibt vor, dass die Mietkaution nicht höher als das 3-Fache der Kaltmiete sein darf.</p>
              <div className="ekform-row">
                <div className="ekform-block-6">
                  <CustomInput
                    inputName="kaution"
                    inputTitle="Höhe Ihrer Kaution"
                    inputType="text"
                    errorMessage="Wir bieten Mietkautionsbürgschaften von 500 EUR bis maximal 15.000 EUR"
                    min={500}
                    max={15000}
                    placeholder="Mietkaution in Euro"
                    pattern="^[0-9]+$"
                    required
                    key={0}
                    value={kaution === 0 ? "" : kaution}
                    callback={(event: ChangeEvent<HTMLInputElement>) => {
                      checkFormInput(event);
                      handleInputChange(event);
                    }}
                  ></CustomInput>
                </div>
                <div className="ekform-block-6">
                  <InputTurnus
                    inputName="paymentInterval"
                    inputTitle="Ihre Zahlungsweise"
                    key={1}
                    callback={(event) => {
                      handleSelectChange(event);
                    }}
                    required
                  ></InputTurnus>
                </div>
              </div>
            </div>
            <PaymentBlock
              PaymentInterval={paymentInterval}
              kaution={isNaN(kaution) ? 0 : kaution ?? 0}
            ></PaymentBlock>
            <div className="section">
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <CustomInput
                  hidden={true}
                    inputType="text"
                    inputName="couponCode"
                    inputTitle=""
                    minLength={0}
                    maxLength={10}
                    value={couponCode}
                    placeholder="Rabattcode"
                    callback={(event) => {
                      checkcoupon(event);
                      handleInputChange(event);
                    }}
                  ></CustomInput>
                  <CustomInput
                    inputName="couponDiscount"
                    inputType="text"
                    inputTitle=""
                    placeholder="Rabatt"
                    required
                    hidden
                    pattern="^[0-9]+$"
                    value={couponCode.length === 0 || couponResponseMessage?.status === 200 ? "1" : ""}
                  ></CustomInput>
                  <div
                    style={{
                      display: couponResponseMessage?.status ? "block" : "none",
                      padding: 15,
                      marginTop: 10,
                      borderRadius: 15,
                    }}
                    className="backgroundcolorcontainer"
                  >
                    <h3
                      style={{
                        color:
                          couponResponseMessage?.status === 200
                            ? "white"
                            : "#999",
                      }}
                    >
                      {couponResponseMessage?.message}
                    </h3>
                  </div>
                </div>
                <div className="ekform-block-12" style={{display:"none"}}>
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Wurden Sie von einem Partner der EuroKaution geworben? Dann geben Sie bitte hier seine Partner-Nummer ein."
                    inputName="referal"
                    minLength={0}
                    hidden={true}
                    maxLength={10}
                    callback={handleInputChange}
                    value={referal}
                  ></CustomInput>
                </div>
                <div className="ekform-block-12">
                  <CustomInput
                    inputType="text"
                    inputTitle="Sind Sie bereits Kunde? Dann geben Sie bitte hier ihre Versicherungsnummer an."
                    placeholder="Versicherungsnummer"
                    inputName="insuranceNumber"
                    minLength={0}
                    maxLength={50}
                    callback={handleInputChange}
                    value={insuranceNumber}
                  ></CustomInput>
                </div>
              </div>
            </div>
            <div className="ekform-row buttonrow">
              <div className="ekform-block-4"></div>
              <div className="ekform-block-4"></div>
              <div className="ekform-block-4">
                <button type="submit" className="section2click">
                  weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    ></ValidationHeader>
  );
};
