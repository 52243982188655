import { ChangeEvent, useState } from "react";
import {
  adress,
  landlord,
  mainPerson,
  tenacy,
  tenacyReason,
} from "../interface";

import {
  CustomInput,
  InputAnrede,
  InputDate,
  InputMietvertrag,
  InputVersand,
} from "../input/Custominput";
import { pages, NavigationHeader } from "../navigation/navigator";
import { useNavigate } from "react-router-dom";
import { ValidationHeader } from "../validation/validate";
import { motion } from "framer-motion";
import { activatedPages } from "../App";
interface Page3Interface {
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  adress: adress;
  landlord: landlord;
  onUnlock: () => void;
  fireevent: (eventdata:string)=> void;
  tenacy: tenacy;
  unlockedPages: activatedPages;
  person1: mainPerson;
}
export const Page3: React.FC<Page3Interface> = ({
  handleInputChange,
  handleSelectChange,
  adress,
  landlord,
  tenacy,
  fireevent,
  onUnlock,
  person1,
  unlockedPages,
}) => {
  const variants = {
    open: { opacity: 1, x: 0, height: "100%", display: "block" },
    closed: { opacity: 0, x: "-100%", height: "0", display: "none" },
  };
  const navigation = useNavigate();
  const timestamp = new Date().getTime();
  const year = new Date(timestamp).getFullYear();
  const month = String(new Date(timestamp).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(timestamp).getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  const todayDate = new Date();

  // Calculate the date 30 days ago
  todayDate.setDate(todayDate.getDate() + 30);

  // Format the date as a string (e.g., "YYYY-MM-DD")
  const formattedDatePlus30 = todayDate.toISOString().split('T')[0];
  return (
    <ValidationHeader
      onUnlock={onUnlock}
      nextform={pages.page4}
      content={
        <section className="ekform-container">
          <NavigationHeader unlockedPages={unlockedPages}></NavigationHeader>
          <div id="section3">
            <div className="section">
              <h2>Angaben zu Ihrem Mietverhältnis</h2>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <InputMietvertrag
                    value={tenacy.reason}
                    callback={(event) => {
                      handleSelectChange(event);
                    }}
                    inputName="tenancy.reason"
                    inputTitle="Grund der Mietkautionsbürgschaft"
                    required={true}
                  ></InputMietvertrag>
                    <p><strong>Neues Mietobjekt:</strong> Sie ziehen um und möchten eine Bürgschaft für Ihr neues Mietobjekt beantragen. <br></br>
                    <strong>Mietkaution umwandeln:</strong> Sie haben eine Barkaution für Ihr Mietobjekt hinterlegt und möchten diese in eine Bürgschaft umwandeln.</p>
                </div>
              </div>
            </div>
            <motion.div
              id="tenacy.convert"
              variants={variants}
              animate={
                tenacy.reason === tenacyReason.convertTenacy ? "open" : "closed"
              }
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <div className="section">
                <h2>Angaben zum Mietobjekt</h2>
                <div className="ekform-row">
                  
                  <div className="ekform-block-10">
                    <CustomInput
                      disabled
                      placeholder="Straße"
                      inputType="text"
                      inputTitle=""
                      value={person1.adress.street}
                      inputName="person1.adress.street"
                      minLength={2}
                      maxLength={30}
                      required={tenacy.reason === tenacyReason.convertTenacy}
                    />
                  </div>
                  <div className="ekform-block-2">
                    <CustomInput
                      placeholder="Hausnummer"
                      inputType="text"
                      inputTitle=""
                      disabled
                      value={person1.adress.housenumber}
                      inputName="person1.adress.housenumber"
                      minLength={1}
                      maxLength={10}
                      required={tenacy.reason === tenacyReason.convertTenacy}
                    />
                  </div>
                  <div className="ekform-block-4">
                    <CustomInput
                      placeholder="PLZ"
                      inputType="text"
                      inputTitle=""
                      inputName="person1.adress.postalCode"
                      minLength={5}
                      value={person1.adress.postalCode}
                      disabled
                      maxLength={5}
                      required={tenacy.reason === tenacyReason.convertTenacy}
                    />
                  </div>
                  <div className="ekform-block-8">
                    <CustomInput
                      inputType="text"
                      inputTitle=""
                      placeholder="Ort"
                      inputName="person1.adress.city"
                      value={person1.adress.city}
                      disabled
                      minLength={2}
                      maxLength={30}
                      required={tenacy.reason === tenacyReason.convertTenacy}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              id="zweitermieter"
              variants={variants}
              animate={
                tenacy.reason === tenacyReason.newObject ? "open" : "closed"
              }
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <div className="section">
                <h2>Angaben zum Mietobjekt</h2>
                <div className="ekform-row">
                  <div className="ekform-block-10">
                    <CustomInput
                      placeholder="Straße"
                      inputType="text"
                      inputTitle=""
                      value={adress.street}
                      callback={handleInputChange}
                      inputName="tenancy.adress.street"
                      minLength={2}
                      maxLength={30}
                      required={tenacy.reason === tenacyReason.newObject}
                    />
                  </div>
                  <div className="ekform-block-2">
                    <CustomInput
                      placeholder="Hausnummer"
                      inputType="text"
                      inputTitle=""
                      callback={handleInputChange}
                      value={adress.housenumber}
                      inputName="tenancy.adress.housenumber"
                      minLength={1}
                      maxLength={10}
                      required={tenacy.reason === tenacyReason.newObject}
                    />
                  </div>
                  <div className="ekform-block-4">
                    <CustomInput
                      placeholder="PLZ"
                      inputType="text"
                      inputTitle=""
                      inputName="tenancy.adress.postalCode"
                      minLength={5}
                      value={adress.postalCode}
                      callback={handleInputChange}
                      maxLength={5}
                      required={tenacy.reason === tenacyReason.newObject}
                    />
                  </div>
                  <div className="ekform-block-8">
                    <CustomInput
                      inputType="text"
                      inputTitle=""
                      placeholder="Ort"
                      inputName="tenancy.adress.city"
                      value={adress.city}
                      callback={handleInputChange}
                      minLength={1}
                      maxLength={25}
                      required={tenacy.reason === tenacyReason.newObject}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="section">
              <h2>Angaben zum Mietvertrag</h2>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <InputDate
                    value={tenacy.contract.signatureDate}
                    inputTitle="Unterschrift Mietvertrag"
                    inputSubTitle="muss in der Vergangenheit liegen"
                    placeholder="XX.XX.XXXX"
                    inputName="tenancy.contract.signatureDate"
                    min="1900-01-01"
                    max={currentDate}
                    callback={handleInputChange}
                    required={true}
                  />
                </div>
                <div className="ekform-block-12">
                  <p>Beginn Bürgschaft</p>
                  <InputDate
                    value={tenacy.contract.contractBeginnDate}
                    callback={handleInputChange}
                    inputTitle="Sie können die Bürgschaft für heute oder für einen späteren Vertragsbeginn (max. 30 Tage) beantragen. Die Bürgschaft wird per Post zugestellt."
                    placeholder="XX.XX.XXXX"
                    inputName="tenancy.contract.contractBeginnDate"
                    min={currentDate}
                    max={formattedDatePlus30}
                    required={true}
                  />
                </div>
                <div className="ekform-block-12" style={{display:"none"}}>
                  <InputDate
                    value={tenacy.contract.moveInDate}
                    callback={handleInputChange}
                    inputTitle="Einzugsdatum"
                    placeholder="XX.XX.XXXX"
                    inputName="tenancy.contract.moveInDate"
                    required={false}
                  />
                </div>
                <div className="ekform-block-12">
                  <InputVersand
                    value={tenacy.shipping}
                    callback={handleSelectChange}
                    inputTitle="Wohin soll die Bürgschaft versendet werden"
                    inputName="tenancy.shipping"
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h2>Angaben zum Vermieter</h2>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Firma"
                    inputName="tenancy.landlord.company"
                    value={landlord.company}
                    callback={handleInputChange}
                    minLength={0}
                    maxLength={30}
                  />
                </div>
                <div className="ekform-block-4">
                  <InputAnrede
                    callback={(event) => {
                      handleSelectChange(event);
                    }}
                    inputTitle=""
                    inputName="tenancy.landlord.title"
                    required={true}
                  />
                </div>
                <div className="ekform-block-4">
                  <CustomInput
                    callback={handleInputChange}
                    value={landlord.firstname}
                    inputType="text"
                    inputTitle=""
                    inputName="tenancy.landlord.firstname"
                    placeholder="Vorname"
                    minLength={2}
                    maxLength={30}
                    required={true} // Use true instead of 'required'
                  />
                </div>
                <div className="ekform-block-4">
                  <CustomInput
                    callback={handleInputChange}
                    value={landlord.lastname}
                    inputType="text"
                    inputTitle=""
                    inputName="tenancy.landlord.lastname"
                    placeholder="Nachname"
                    minLength={2}
                    maxLength={30}
                    required={true} // Use true instead of 'required'
                  />
                </div>
                <div className="ekform-block-10">
                  <CustomInput
                    callback={handleInputChange}
                    value={landlord.adress.street}
                    inputType="text"
                    inputTitle=""
                    inputName="tenancy.landlord.adress.street"
                    placeholder="Straße"
                    minLength={2}
                    maxLength={30}
                    required={true} // Use true instead of 'required'
                  />
                </div>
                <div className="ekform-block-2">
                  {" "}
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    inputName="tenancy.landlord.adress.housenumber"
                    callback={handleInputChange}
                    value={landlord.adress.housenumber}
                    placeholder="Hausnummer"
                    minLength={1}
                    maxLength={10}
                    required={true} // Use true instead of 'required'
                  />
                </div>
                <div className="ekform-block-4">
                  {" "}
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    inputName="tenancy.landlord.adress.postalCode"
                    callback={handleInputChange}
                    value={landlord.adress.postalCode}
                    placeholder="PLZ"
                    minLength={5}
                    maxLength={5}
                    required={true} // Use true instead of 'required'
                    pattern="^[0-9]{5}$"
                  />
                </div>
                <div className="ekform-block-8">
                  {" "}
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    callback={handleInputChange}
                    value={landlord.adress.city}
                    inputName="tenancy.landlord.adress.city"
                    placeholder="Ort"
                    minLength={1}
                    maxLength={25}
                    required={true}
                  />
                </div>
                <div className="ekform-block-6">
                  <CustomInput
                    inputType="tel"
                    inputTitle=""
                    callback={handleInputChange}
                    value={landlord.contact.phone}
                    inputName="tenancy.landlord.contact.phone"
                    placeholder="Telefonnummer"
                    maxLength={15}
                  />
                </div>
                <div className="ekform-block-6">
                  <CustomInput
                    callback={handleInputChange}
                    value={landlord.contact.mail}
                    inputType="email"
                    inputTitle=""
                    inputName="tenancy.landlord.contact.mail"
                    maxLength={50}
                    placeholder="E-Mail-Adresse"
                  />
                </div>
                <div className="ekform-block-12">
                  <p>Sie verpflichten sich, den Vermieter nach der EU-DSGVO zu informieren, wenn Sie uns dessen personenbezogene Daten erstmalig mitteilen oder mitteilen lassen.</p>
                </div>
              </div>
            </div>
            <div className="ekform-row buttonrow">
              <div className="ekform-block-2">
                <button
                  className="section2click backbutton"
                  onClick={() => {
                    window.parent.postMessage("complete", '*');fireevent("kautionscheck.click.back");navigation("/step-2");
                  }}
                >
                  zurück
                </button>
              </div>
              <div className="ekform-block-6"></div>
              <div className="ekform-block-4">
                <button className="section4click">weiter</button>
              </div>
            </div>
          </div>
        </section>
      }
    ></ValidationHeader>
  );
};
