import "./App.css";
import { ChangeEvent, useEffect, useState } from "react";
import { eurokaution, eurokautionDefaultObject } from "./interface";
import { Page1 } from "./pages/page1";
import { Page2 } from "./pages/page2";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Page3 } from "./pages/page3";
import { Page4 } from "./pages/page4";
import { Page5 } from "./pages/page5";
import { handleSubmit } from "./api/send";
import { Page6 } from "./pages/page6";
export interface activatedPages {
  page1: boolean;
  page2: boolean;
  page3: boolean;
  page4: boolean;
  page5: boolean;
}
function App() {
  const [activatedPages, setActivatedPages] = useState<activatedPages>({
    page1: true,
    page2: false,
    page3: false,
    page4: false,
    page5: false,
  });

  const [navigateNext, setNavigateNext] = useState<boolean>(false);
  const [kautionFormData, setKautionFormData] = useState<eurokaution>(
    eurokautionDefaultObject
  );
  const allvaluesFilled = (inputValues: eurokaution) => {
    // eslint-disable-next-line no-restricted-globals
    if (inputValues.kaution === 0 && location.pathname !== "/") {
      window.location.href = "/";
    }
  };
  const updateNestedProperty = (
    obj: eurokaution,
    name: string,
    value: string | number | boolean
  ): eurokaution => {
    const keys = name.split("."); // Split the name by '.'
    const updatedObj = { ...obj };
    // Use reduce to traverse the object and update the nested property
    keys.reduce((currentObj, key, index) => {
      if (index === keys.length - 1) {
        // If it's the last key, update the value
        (currentObj as any)[key] =
          typeof currentObj[key as keyof eurokaution] === "number"
            ? parseInt(value as string)
            : value;
      } else {
        // If not the last key, make sure the nested objects exist
        if (!(currentObj as any)[key]) {
          (currentObj as any)[key] = {};
        }
      }
      return (currentObj as any)[key];
    }, updatedObj);
    return updatedObj as eurokaution;
  };
  function fireevent(eventdata: string) {
    if (window.parent !== null) {
      console.log("is in iframe")
      console.log("eventdatalog: ",eventdata);
      window.parent.postMessage(
        // Serialize the message
        JSON.stringify({
          event: eventdata,
          custom: true,
          'gtm.elementUrl': 'https://www.eurokaution.de',
          pageUrl: document.location.href,
          pageTitle: document.title
        }),
        'https://www.eurokaution.de'
      );
    }
  }
  useEffect(() => {
    allvaluesFilled(kautionFormData);
    const script = document.createElement("script");
    script.src = "/iframeResizer.js";
    script.async = true;
    document.body.appendChild(script);
    fireevent("kautionscheck.page.1")
  }, []);
  function ScrollToTop() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      window.parent.postMessage("complete", '*');
    }, 100);

    return null;
  }
  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const props = name.split(".");
    if (!kautionFormData) return;
    if (props.length > 1) {
      setKautionFormData(updateNestedProperty(kautionFormData, name, value));
      return;
    }
    await setKautionFormData({
      ...kautionFormData,
      [name]:
        typeof kautionFormData[name as keyof eurokaution] === "number"
          ? isNaN(parseInt(!value.length ? "0" : value))
            ? 0
            : parseInt(!value.length ? "0" : value)
          : value,
    });
  };
  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const props = name.split(".");
    if (!kautionFormData) return;
    if (props.length > 1) {
      setKautionFormData(updateNestedProperty(kautionFormData, name, checked));
      return;
    }
    setKautionFormData({
      ...kautionFormData,
      [name]: checked,
    });
  };
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (!kautionFormData) return;
    const props = name.split(".");
    if (props.length > 1) {
      setKautionFormData(updateNestedProperty(kautionFormData, name, value));
      return;
    }
    setKautionFormData({
      ...kautionFormData,
      [name]: parseInt(value),
    });
    console.log(kautionFormData);
  };
  const timestamp = new Date().getTime();
  const year = new Date(timestamp).getFullYear() - 18;
  const month = String(new Date(timestamp).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(timestamp).getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Page1
              onUnlock={() => {

                setActivatedPages({ ...activatedPages, page2: true });
                fireevent("kautionscheck.click.next")
                fireevent("kautionscheck.page.2")
                ScrollToTop();
              }}
              insuranceNumber={kautionFormData.insuranceNumber}
              unlockedPages={activatedPages}
              couponCode={kautionFormData.couponCode}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              kaution={kautionFormData.kaution}
              paymentInterval={kautionFormData.paymentInterval}
              referal={kautionFormData.referal}
            ></Page1>
          }
        />
        <Route
          path="/step-2"
          element={
            <Page2
              onUnlock={() => {
                setActivatedPages({ ...activatedPages, page3: true });
                ScrollToTop();
                fireevent("kautionscheck.click.next")
                fireevent("kautionscheck.page.3")
              }}
              unlockedPages={activatedPages}
              firstName={kautionFormData.person1.firstname}
              currentDate={currentDate}
              person1={kautionFormData.person1}
              person2={kautionFormData.person2}
              handleInputChange={handleInputChange}
              handleCheckBoxChange={handleCheckBoxChange}
              handleSelectChange={handleSelectChange}
              fireevent={fireevent}
            ></Page2>
          }
        />
        <Route
          path="/step-3"
          element={
            <Page3
              onUnlock={() => {
                setActivatedPages({ ...activatedPages, page4: true });
                fireevent("kautionscheck.click.next")
                fireevent("kautionscheck.page.4")
                ScrollToTop();
              }}
              unlockedPages={activatedPages}
              person1={kautionFormData.person1}
              tenacy={kautionFormData.tenancy}
              adress={kautionFormData.tenancy.adress}
              landlord={kautionFormData.tenancy.landlord}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              fireevent={fireevent}
            ></Page3>
          }
        />
        <Route
          path="/step-4"
          element={
            <Page4
              firstName={kautionFormData.person1.firstname}
              lastName={kautionFormData.person1.lastname}
              onUnlock={() => {
                setActivatedPages({ ...activatedPages, page5: true });
                ScrollToTop();
                fireevent("kautionscheck.click.next")
                fireevent("kautionscheck.page.5")
              }}
              unlockedPages={activatedPages}
              handleCheckBoxChange={handleCheckBoxChange}
              payment={kautionFormData.payment}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              fireevent={fireevent}
            ></Page4>
          }
        />
        <Route
          path="/step-5"
          element={
            <Page5
              navigateNext={navigateNext}
              onUnlock={async () => {
                const response = await handleSubmit(kautionFormData);
                if (response.success) {
                  setNavigateNext(true)
                  ScrollToTop();
                  //navigate("/step-6");
                  fireevent("kautionscheck.click.next")
                  fireevent("kautionscheck.form.finish")
                }
                else {
                  alert(response.message);
                }
              }}
              unlockedPages={activatedPages}
              handleCheckBoxChange={handleCheckBoxChange}
              legal={kautionFormData.legal}
              fireevent={fireevent}
            ></Page5>
          }
        />
        <Route path="/step-6" element={<Page6></Page6>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
