import { useNavigate } from "react-router-dom";
import { pages, NavigationHeader } from "../navigation/navigator";
import { eurokaution, legalOptions } from "../interface";
import { ChangeEvent, useEffect } from "react";
import { handleSubmit } from "../api/send";
import { ValidationHeader } from "../validation/validate";
import { activatedPages } from "../App";

interface Page6Interface {}
export const Page6: React.FC<Page6Interface> = ({}) => {
  const navigation = useNavigate();
  useEffect(()=>{
  })
  return (
    <>
      <div className="ekform-container">
       
        <div id="section1">
          <div className="section">
            <h2>Vielen Dank für Ihr Vertrauen!</h2>
            <p>Sie haben es fast geschafft. Bitte bestätigen Sie nur noch Ihre E-Mail-Adresse in der E-Mail, die wir Ihnen soeben gesendet haben. 
Im Anschluss erhalten Sie nach einer Überprüfung die Bestätigung Ihrer Kautionsbürgschaft.</p>
<p>Sollten Sie keine E-Mail erhalten haben, überprüfen Sie bitte auch Ihren SPAM-Ordner.
            </p>      
          </div>
        </div>
      </div>
      
      
      
      
    </>
  );
};
