import { useEffect, useRef, useState } from "react";
import { paymentInterval } from "../interface";
interface InputProps {
  kaution: number;
  PaymentInterval: paymentInterval;
}
export const PaymentBlock: React.FC<InputProps> = ({
  kaution,
  PaymentInterval,
}) => {
  const [zahlweise, setZahlweise] = useState("Monat");
  const [jahresgebuehr, setJahresgebuehr] = useState(0);
  const [zahlbetrag, setZahlbetrag] = useState(0);
  const [monatlich, setMonatlich] = useState(0);
  const [hideModal, setHideModal] = useState(true);
  const [hideMonthly, setHideMonthly] = useState(true);
  useEffect(() => {
    handleTurnusChange();
  }, [PaymentInterval, kaution]);
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  const handleTurnusChange = () => {
    let annualPayment = 0;
    let paymentAmount = 0;
    let calcKaution = kaution;
    if (isNaN(calcKaution)) {
      setHideModal(true);
      return;
    }
    if (!(calcKaution > 0)) {
      setHideModal(true);
      return;
    }
    setHideModal(false);
    if (calcKaution < 500) {
      calcKaution = 500;
    }
    if (calcKaution > 15000) {
      calcKaution = 15000;
    }
    switch (PaymentInterval) {
      case paymentInterval.monthly:
        setHideMonthly(true);
        break;
      default:
        setHideMonthly(false);
    }
    switch (PaymentInterval) {
      case paymentInterval.monthly:
        setZahlweise("Monat");
        annualPayment = calcKaution * 0.055;
        if (annualPayment < 60) {
          annualPayment = 60;
        }
        paymentAmount = annualPayment / 12;
        setJahresgebuehr(annualPayment);
        setMonatlich(paymentAmount / 1);
        setZahlbetrag(paymentAmount);
        break;
      case paymentInterval.quarterly:
        setZahlweise("Vierteljahr");
        annualPayment = calcKaution * 0.052;
        if (annualPayment < 60) {
          annualPayment = 60;
        }
        paymentAmount = annualPayment / 4;
        setJahresgebuehr(annualPayment);
        setMonatlich(paymentAmount / 3);
        setZahlbetrag(paymentAmount);
        break;
      case paymentInterval.halfYearly:
        setZahlweise("Halbjahr");
        annualPayment = calcKaution * 0.049;
        if (annualPayment < 60) {
          annualPayment = 60;
        }
        paymentAmount = annualPayment / 2;
        setJahresgebuehr(annualPayment);
        setMonatlich(paymentAmount / 6);
        setZahlbetrag(paymentAmount);
        break;
      case paymentInterval.yearly:
        setZahlweise("Jahr");
        annualPayment = calcKaution * 0.047;
        if (annualPayment < 50) {
          annualPayment = 50;
        }
        paymentAmount = annualPayment / 1;
        setJahresgebuehr(annualPayment);
        setMonatlich(paymentAmount / 12);
        setZahlbetrag(paymentAmount);
        break;
      default:
      // code block
    }
  };
  return (
    <div className="slidedown" style={{opacity:(hideModal)?0:1,height:(hideModal)?0:(hideMonthly)?"110px":"150px"}}>
      <div className="section ergebnis">
        <div className="ekform-row">
          <div className="ekform-block-12">
            <h1 id="ergebnis1">
              {formatter.format(zahlbetrag)} / {zahlweise}
            </h1>
            <p id="ergebnis2" className="slidedown" hidden={hideMonthly}>
              entspricht {formatter.format(monatlich)} pro Monat
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
