import React, { useState } from "react";
import { eurokaution } from "../interface";
function geturlparameter() {
  // eslint-disable-next-line no-restricted-globals
  const full = location.protocol + "//" + location.host;
  if (full.includes(":3001")) {
    return "http://localhost:3000/";
  }
  return "";
}
export const handleSubmit = async (formdata: eurokaution):Promise<{success:boolean,message: string}> => {
  console.log(JSON.stringify(formdata));
  try {
    const response = await fetch(geturlparameter() + "api/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    });

    if (response.ok) {
      console.log("Form submitted successfully");
      return {
        message: "Erfolgreich gespeichert",
        success: true
      }
      // You can redirect or perform any other action here
    } else {
      try {
        let responseJSON = await response.json()
        console.log(responseJSON[0].type)
        if(responseJSON.length===1 && responseJSON[0].type && responseJSON[0].type==="Body"){
          return {
            message: "Fehler: "+responseJSON[0].errors.issues[0].message+" im Feld: "+responseJSON[0].errors.issues[0].path.slice(-1),
            success: false
          }
        }
        else{
          return {
            message: "Beim Versenden ist ein Fehler aufgetreten",
            success: false
          }
        }
      } catch (error) {
        return {
          message: "Fehler beim Parsing",
          success: false
        }
      }
      console.error("Form submission failed");
      return {
        message: "Fehler",
        success: false
      };
    }
  } catch (error) {
    console.error("Error:", error);
    return {
      message: "Fehler",
      success: false
    }
  }
};

export const handlecouponsubmit = async (coupon: string) => {
  console.log(JSON.stringify({ code: coupon }));
  try {
    const response = await fetch(geturlparameter() + "api/coupon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: coupon }),
    });

    if (response.ok) {
      let message = await response.json();
      console.log("Form submitted successfully");
      return {
        status: 200,
        message: message.message,
        percentage: message.percentage,
      };
      // You can redirect or perform any other action here
    } else {
      var messagereturn;
      try {
        messagereturn = await response.json();
      } catch (error) {
        messagereturn = { message: "Fehler", percentage: 0 };
      }
      return {
        status: response.status,
        message: messagereturn.message,
        percentage: messagereturn.message,
      };
    }
  } catch (error) {
    console.error("Error:", error);
  }
};