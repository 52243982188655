export interface eurokaution {
  insuranceNumber: string;
  kaution: number;
  referal: string;
  couponCode: string;
  couponDiscount: string;
  paymentInterval: paymentInterval;
  person1: mainPerson;
  person2?: person2;
  tenancy: tenacy;
  payment: paymentOptions;
  legal: legalOptions;
  ruvResponse?: any;
}
export interface tenacy {

  reason: tenacyReason;
  adress: adress;
  contract: {
    signatureDate: string;
    contractBeginnDate: string;
    moveInDate: string;
  };
  shipping: shippingOptions;
  landlord: landlord;

}
export interface legalOptions {
  checkedDocuments: {
    document1: boolean;
    document2: boolean;
    document4: boolean;
    document5: boolean;
  };
  checkedDSGVODocuments: {
    document1: boolean;
    document2: boolean;
  };
}

export interface paymentOptions {
  iban: string;
  bic: string;
  creditInstitution: string;
  allowsepa: boolean;
}
interface removeBirthDate {
  birthdate: string;
}
type KeysAfterRemoval = Omit<mainPerson, keyof removeBirthDate>
export interface landlord extends KeysAfterRemoval {
  company: string;
}
export interface person2 extends person {
  dsgvo: boolean;
}
export enum shippingOptions {
  "policyholder",
  "landlord",
}
export enum tenacyReason {
  "convertTenacy" = 0,
  "newObject" = 1,
}
export interface adress {
  street: string;
  housenumber: string;
  postalCode: string;
  city: string;
}
export interface mainPerson extends person {
  adress: adress;
  contact: {
    phone: string;
    mail: string;
  };
}
export interface person {
  salutation: salutation;
  title: string;
  firstname: string;
  lastname: string;
  birthdate: string;
}
export enum paymentInterval {
  "monthly" = 0,
  "quarterly" = 1,
  "halfYearly" = 2,
  "yearly" = 3,
}
export enum salutation {
  "Mr" = 0,
  "Mrs" = 1,
  "various" = 2,
}

export let eurokautionDefaultObject: eurokaution = {
  couponCode: "",
  couponDiscount: "",
  insuranceNumber: "",
  kaution: 0,
  legal: {
    checkedDocuments: {
      document1: false,
      document2: false,
      document4: false,
      document5: false,
    },
    checkedDSGVODocuments: {
      document1: false,
      document2: false,
    },
  },
  payment: {
    bic: "",
    creditInstitution: "",
    iban: "",
    allowsepa: false,
  },
  paymentInterval: paymentInterval.yearly,
  person1: {
    adress: {
      city: "",
      housenumber: "",
      postalCode: "",
      street: "",
    },
    birthdate: "",
    contact: {
      mail: "",
      phone: "",
    },
    firstname: "",
    lastname: "",
    salutation: salutation.Mr,
    title: "",
  },
  person2: {
    birthdate: "",
    firstname: "",
    lastname: "",
    salutation: salutation.Mr,
    title: "",
    dsgvo: false,
  },
  referal: "",
  tenancy: {
    adress: {
      city: "",
      housenumber: "",
      postalCode: "",
      street: "",
    },
    contract: {
      contractBeginnDate: "",
      moveInDate: "",
      signatureDate: "",
    },
    landlord: {
      adress: {
        city: "",
        housenumber: "",
        postalCode: "",
        street: "",
      },
      //birthdate: "",
      firstname: "",
      lastname: "",
      salutation: salutation.Mr,
      title: "",
      company: "",
      contact: {
        mail: "",
        phone: "",
      },
    },
    shipping: shippingOptions.landlord,
    reason: tenacyReason.newObject,
  },
};
