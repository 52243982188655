import { useNavigate } from "react-router-dom";
import { pages, NavigationHeader } from "../navigation/navigator";
import { eurokaution, legalOptions } from "../interface";
import { ChangeEvent, useEffect, useRef } from "react";
import { handleSubmit } from "../api/send";
import { ValidationHeader } from "../validation/validate";
import { activatedPages } from "../App";

interface Page5Interface {
  navigateNext: boolean;
  legal: legalOptions;
  handleCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  unlockedPages: activatedPages;
  onUnlock: () => void;
  fireevent: (eventdata:string) => void;
}
export const Page5: React.FC<Page5Interface> = ({
  navigateNext,
  legal,
  handleCheckBoxChange,
  unlockedPages,
  fireevent,
  onUnlock,
}) => {

  const checkboxRef1 = useRef(null);
  const checkboxRef2 = useRef(null)
  const checkboxRef4 = useRef(null)
  const checkboxRef5 = useRef(null)
  const navigate = useNavigate();
  function allCheckedOrUnchecked() {
    if (checkboxRef1.current && checkboxRef2.current && checkboxRef4.current && checkboxRef5.current) {
      /*  if (!(checkboxRef1.current as any).checked && !(checkboxRef2.current as any).checked && !(checkboxRef3.current as any).checked && !(checkboxRef4.current as any).checked && !(checkboxRef5.current as any).checked) {
          return false;
        }*/
      if ((checkboxRef1.current as any).checked && (checkboxRef2.current as any).checked && (checkboxRef4.current as any).checked && (checkboxRef5.current as any).checked) {
        return true;
      }
      return false;
    }
    return false;
  }
  function checkAll(setState: boolean) {
    if ((checkboxRef1.current as any).checked !== setState) {
      (checkboxRef1.current as any).click();
    }
    if ((checkboxRef2.current as any).checked !== setState) {
      (checkboxRef2.current as any).click();
    }
    if ((checkboxRef4.current as any).checked !== setState) {
      (checkboxRef4.current as any).click();
    }
    if ((checkboxRef5.current as any).checked !== setState) {
      (checkboxRef5.current as any).click();
    }
  }
  useEffect(() => {
    if (navigateNext) {
      navigate("/step-6");
    }
  }, [navigateNext])
  return (
    <ValidationHeader
      onUnlock={onUnlock}
      nextform={pages.page6}
      content={
        <section className="ekform-container">
          <NavigationHeader unlockedPages={unlockedPages}></NavigationHeader>
          <div id="section5">
            <div className="section">
              <h2>Wichtige Dokumente</h2>
              <p>Bitte bestätigen Sie, dass Sie die folgenden Dokumente gelesen und für Ihre Unterlagen heruntergeladen haben.</p>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <input
                    onChange={handleCheckBoxChange}
                    ref={checkboxRef1}
                    type="checkbox"
                    id="dokument1"
                    name="legal.checkedDocuments.document1"
                    value="Allgemeine Versicherungsbedingungen"
                    required
                  />
                  <label><a href="https://www.eurokaution.de/allgemeine-bedingung-zur-rv-mietkautionsburgschaft/" target="_blank">Allgemeine Versicherungsbedingungen</a></label>
                </div>
                <div className="ekform-block-12">
                  <input
                    ref={checkboxRef2}
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    id="dokument2"
                    name="legal.checkedDocuments.document2"
                    value="Produktinformationsblatt"
                    required
                  />
                  <label><a href="https://www.eurokaution.de/produktinformationsblatt-zur-rv-mietkautionsburgschaft/" target="_blank">Produktinformationsblatt</a></label>
                </div>
                <div className="ekform-block-12">
                  <input
                    ref={checkboxRef4}
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    id="dokument4"
                    name="legal.checkedDocuments.document4"
                    value="Merkblatt zur Datenverarbeitung"
                    required
                  />
                  <label><a href="https://www.eurokaution.de/merkblatt-zur-datenverarbeitung/" target="_blank">Merkblatt zur Datenverarbeitung</a></label>
                </div>
                <div className="ekform-block-12">
                  <input
                    ref={checkboxRef5}
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    id="dokument5"
                    name="legal.checkedDocuments.document5"
                    value="Versicherungsmaklerauftrag"
                    required
                  />
                  <label><a href="https://www.eurokaution.de/versicherungsmaklerauftrag/" target="_blank">Versicherungsmaklerauftrag</a></label>
                </div>
                <button type="button" className="checkall" onClick={() => {
                  if (checkboxRef1.current && checkboxRef2.current && checkboxRef4.current && checkboxRef5.current) {
                    checkAll(!allCheckedOrUnchecked());
                  }
                }}>alles {(allCheckedOrUnchecked() === true) ? 'Abwählen' : (allCheckedOrUnchecked() === false) ? 'Auswählen' : ''}</button>
              </div>
            </div>
            <div className="section">
              <h2>Wir gehen Verantwortungsvoll mit Ihren Daten um</h2>
              <p>Hinweis: Alle Angaben sind freiwillig. Ihre Einwilligung in die Erhebung personenbezogener Daten ist jederzeit widerruflich (per E-Mail an <a href="mailto:datenschutz@eurokaution.de">datenschutz@eurokaution.de</a> oder an die im <a href="https://www.eurokaution.de/impressum/" target="_blank">Impressum</a> angegebenen Kontaktdaten). Die Datenerhebung und Nutzung erfolgt entsprechend unserer <a href="https://www.eurokaution.de/datenschutz/" target="_blank">Datenschutzerklärung</a>.</p>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <input
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    id="dsgvo1"
                    name="legal.checkedDSGVODocuments.document1"
                    value="Aktzeptanz Datenverarbeitung"
                    required
                  />
                  <label>Ja, ich stimme der Erhebung, Verarbeitung und Nutzung meiner oben angegebene Daten zu. Ich versichere, über 18 Jahre alt zu sein, andernfalls erkläre ich, dass meine Eltern mit der Erhebung, Verarbeitung und Nutzung meiner oben angegebenen Daten einverstanden sind.</label>
                </div>
              </div>
              <div className="ekform-row">
                <div className="ekform-block-12">
                  <input
                    onChange={handleCheckBoxChange}
                    type="checkbox"
                    id="dsgvo2"
                    name="legal.checkedDSGVODocuments.document2"
                    value="Aktzeptanz Datenverarbeitung"
                    required
                  />
                  <label><a href="https://www.eurokaution.de/einwilligungserklaerung/" target="_blank">Ich willige ein, dass meine Daten gemäß der Einwilligungserklärung zur Verwendung der allgemeinen personenbezogenen Daten durch EuroKaution verarbeitet werden.</a></label>
                </div>
              </div>
            </div>
            <div className="ekform-row buttonrow">
              <div className="ekform-block-2">
                <button
                  className="section4click backbutton"
                  onClick={() => { window.parent.postMessage("complete", '*'); fireevent("kautionscheck.click.back");navigate("/step-4") }}
                  type="button"
                >
                  zurück
                </button>
              </div>
              <div className="ekform-block-6"></div>
              <div className="ekform-block-4">
                <button className="">Antrag absenden</button>
              </div>
            </div>
          </div>
        </section>
      }
    ></ValidationHeader>
  );
};
