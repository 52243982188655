import { useLocation, useNavigate } from "react-router-dom";
import { activatedPages } from "../App";
interface NavigationHeaderInterface {
  unlockedPages: activatedPages;
}
export enum pages {
  "page1" = 1,
  "page2" = 2,
  "page3" = 3,
  "page4" = 4,
  "page5" = 5,
  "page6" = 6,
}
export const NavigationHeader: React.FC<NavigationHeaderInterface> = ({
  unlockedPages,
}) => {
  function navigateToNextPage(page: pages) {}
  function currentForm(): pages | undefined {
    if (location.pathname === "/") {
      return pages.page1;
    }
    if (location.pathname === "/step-2") {
      return pages.page2;
    }
    if (location.pathname === "/step-3") {
      return pages.page3;
    }
    if (location.pathname === "/step-4") {
      return pages.page4;
    }
    if (location.pathname === "/step-5") {
      return pages.page5;
    }
    if (location.pathname === "/step-6") {
      return pages.page6;
    }
    return undefined;
  }
  function unlockPage(Pages: pages): boolean {
    const current = currentForm();
    if (!current) return false;
    if (Pages <= current) {
      return true;
    }
    return false;
  }
  const navigation = useNavigate();
  const location = useLocation();
  return (
    <div className="ekform-progressbar">
      <ol className="progressbar">
        <li
          id="step1"
          className={
            "progressbar-step " +
            (location.pathname === "/" ? "is-current" : "")
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (unlockedPages.page1) {
                navigation("/");
              }
            }}
            className="section1click"
          >
            Berechnen
          </a>
        </li>
        <li
          id="step2"
          className={
            "progressbar-step " +
            (location.pathname === "/step-2" ? "is-current" : "")
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (unlockedPages.page2) {
                navigation("/step-2");
              }
            }}
            className="section2click"
          >
            Ihre Angaben
          </a>
        </li>
        <li
          id="step3"
          className={
            "progressbar-step " +
            (location.pathname === "/step-3" ? "is-current" : "")
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (unlockedPages.page3) {
                navigation("/step-3");
              }
            }}
            className="section3click"
          >
            Mietobjekt
          </a>
        </li>
        <li
          id="step4"
          className={
            "progressbar-step " +
            (location.pathname === "/step-4" ? "is-current" : "")
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (unlockedPages.page4) {
                navigation("/step-4");
              }
            }}
            className="section4click"
          >
            Zahlung
          </a>
        </li>
        <li
          id="step5"
          className={
            "progressbar-step " +
            (location.pathname === "/step-5" ? "is-current" : "")
          }
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (unlockedPages.page5) {
                navigation("/step-5");
              }
            }}
            className="section5click"
          >
            Rechtliches
          </a>
        </li>
      </ol>
    </div>
  );
};
