import { useNavigate } from "react-router-dom";
import { CustomInput, InputAnrede, InputDate } from "../input/Custominput";
import { ChangeEvent, useEffect, useState } from "react";
import { pages, NavigationHeader } from "../navigation/navigator";
import { mainPerson, person, person2 } from "../interface";
import { motion } from "framer-motion";
import { ValidationHeader } from "../validation/validate";
import { activatedPages } from "../App";
interface Page2Interface {
  currentDate: string;
  firstName: string;
  unlockedPages: activatedPages;
  person1: mainPerson;
  onUnlock: () => void;
  person2: person2 | undefined;
  fireevent: (eventData:string)=>void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
const variants = {
  open: { opacity: 1, x: 0, height: "100%", display: "block" },
  closed: { opacity: 0, x: "-100%", height: "0", display: "none" },
};

export const Page2: React.FC<Page2Interface> = ({
  currentDate,
  person1,
  person2,
  fireevent,
  firstName,
  onUnlock,
  unlockedPages,
  handleSelectChange,
  handleCheckBoxChange,
  handleInputChange,
}) => {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState((person2?.lastname.length ?? 0 > 2) ? true : false);

  return (
    <ValidationHeader
      onUnlock={onUnlock}
      nextform={pages.page3}
      content={
        <div className="ekform-container">
          <NavigationHeader unlockedPages={unlockedPages}></NavigationHeader>
          <div id="section2">
            <div className="section">
              <h2>Persönliche Angaben</h2>
              <div className="ekform-row">
                <div className="ekform-block-4">
                  <InputAnrede
                    callback={(event) => {
                      handleSelectChange(event);
                    }}
                    value={person1.salutation}
                    inputTitle=""
                    inputName="person1.salutation"
                    required={true}
                  />
                </div>
                <div className="ekform-block-4">
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Vorname"
                    inputName="person1.firstname"
                    minLength={2}
                    maxLength={30}
                    required
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    value={firstName}
                  ></CustomInput>
                </div>
                <div className="ekform-block-4">
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Nachname"
                    inputName="person1.lastname"
                    minLength={2}
                    maxLength={30}
                    value={person1.lastname}
                    required
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                  ></CustomInput>
                </div>

                <div className="ekform-block-12">
                  <InputDate
                    value={person1.birthdate}
                    inputTitle="Geburtsdatum"
                    placeholder="XX.XX.XXXX"
                    inputName="person1.birthdate"
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    min="1900-01-01"
                    max={currentDate}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h2>derzeitige Wohnadresse</h2>
              <div className="ekform-row">
                <div className="ekform-block-10">
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Straße"
                    inputName="person1.adress.street"
                    minLength={2}
                    value={person1.adress.street}
                    maxLength={30}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    required={true}
                  />
                </div>
                <div className="ekform-block-2">
                  {" "}
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Hausnummer"
                    value={person1.adress.housenumber}
                    inputName="person1.adress.housenumber"
                    minLength={1}
                    maxLength={10}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    required={true}
                  />
                </div>
                <div className="ekform-block-4">
                  {" "}
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="PLZ"
                    errorMessage="Bitte geben Sie eine gültige Postleitzahl ein."
                    inputName="person1.adress.postalCode"
                    minLength={5}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    maxLength={5}
                    value={person1.adress.postalCode}
                    required={true}
                    pattern="^[0-9]{5}$"
                  />
                </div>
                <div className="ekform-block-8">
                  <CustomInput
                    inputType="text"
                    inputTitle=""
                    placeholder="Ort"
                    inputName="person1.adress.city"
                    minLength={1}
                    maxLength={25}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                    required={true}
                    value={person1.adress.city}
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h2>Kontaktdaten</h2>
              <div className="ekform-row">
                <div className="ekform-block-6">
                  <CustomInput
                    inputType="tel"
                    inputTitle=""
                    placeholder="Telefonnummer"
                    inputName="person1.contact.phone"
                    minLength={1}
                    maxLength={15}
                    required={true}
                    value={person1.contact.phone}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                  />
                </div>
                <div className="ekform-block-6">
                  <CustomInput
                    inputType="email"
                    inputTitle=""
                    placeholder="E-Mail-Adresse"
                    inputName="person1.contact.mail"
                    errorMessage="Bitte geben Sie eine gültige E-Mail-Adresse ein."
                    minLength={7}
                    maxLength={50}
                    required={true}
                    value={person1.contact.mail}
                    callback={(event) => {
                      handleInputChange(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  !isOpen ? setIsOpen(true) : setIsOpen(false);
                }}
                id="zweitermieterja"
              >
                <h2>Möchten Sie einen zweiten Mieter erfassen?</h2>
                <p>
                  Sie mieten das Objekt nicht alleine? Wir bieten Ihnen die
                  Möglichkeit, einen zweiten Mieter in die
                  Mietkautions­bürgschaft mit aufzunehmen.
                </p>{" "}
                <button>
                  {!isOpen
                    ? "Ja, zweiten Mieter erfassen"
                    : "Nein, keinen zweiten Mieter erfassen"}
                </button>
              </a>

              <motion.div
                id="zweitermieter"
                variants={variants}
                animate={isOpen ? "open" : "closed"}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                <div className="ekform-row">
                  <div className="ekform-block-4">
                    <InputAnrede
                      callback={handleSelectChange}
                      inputTitle="" // Set the inputTitle to "Anrede"
                      inputName="person2.salutation" // Set the inputName to "mieter2-anrede"
                      required={isOpen}
                    />
                  </div>
                  <div className="ekform-block-4">
                    <CustomInput
                      inputType="text"
                      inputTitle=""
                      placeholder="Vorname"
                      inputName="person2.firstname"
                      minLength={2}
                      callback={handleInputChange}
                      value={person2?.firstname}
                      maxLength={30}
                      required={isOpen}
                    />
                  </div>
                  <div className="ekform-block-4">
                    <CustomInput
                      placeholder="Nachname"
                      inputType="text"
                      inputTitle=""
                      inputName="person2.lastname"
                      minLength={2}
                      maxLength={30}
                      value={person2?.lastname}
                      callback={handleInputChange}
                      required={isOpen}
                    />
                  </div>
                  <div className="ekform-block-12">
                    <InputDate
                      callback={handleInputChange}
                      inputTitle="Geburtsdatum"
                      placeholder="XX.XX.XXXX"
                      inputName="person2.birthdate"
                      value={person2?.birthdate}
                      min="1900-01-01"
                      max={currentDate}
                      required={isOpen}
                    />
                  </div>
                  <div className="ekform-block-12">
                    <fieldset>
                      <input
                        type="checkbox"
                        id="mieter2-dsgvo"
                        name="person2.dsgvo"
                        onChange={handleCheckBoxChange}
                        required={isOpen}
                        value="Datenschutz Mieter2"
                      />
                      <label>
                        Sie stimmen im Namen des zweiten Mieters dem Merkblatt
                        zur Datenverarbeitung zu. Sie müssen den von Ihnen
                        benannten zusätzlichen Mieter darüber informieren, dass
                        Sie dessen personenbezogenen Daten im Rahmen dieser
                        Antragsstrecke eingegeben haben. Außerdem müssen Sie dem
                        zusätzlichen Mieter das Merkblatt zur Datenverarbeitung
                        aushändigen.{" "}
                      </label>
                    </fieldset>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="ekform-row buttonrow">
              <div className="ekform-block-2">
                <button
                  className="section1click backbutton"
                  onClick={() => {
                    window.parent.postMessage("complete", '*'); fireevent("kautionscheck.click.back");navigation("/");
                  }}
                >
                  zurück
                </button>
              </div>
              <div className="ekform-block-6"></div>
              <div className="ekform-block-4">
                <button className="section3click">weiter</button>
              </div>
            </div>
          </div>
        </div>
      }
    ></ValidationHeader>
  );
};
