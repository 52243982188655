import React, { ChangeEvent, createRef, useRef, useState } from "react";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";

import {
  paymentInterval,
  salutation,
  shippingOptions,
  tenacyReason,
} from "../interface";

interface InputProps {
  inputType: "text" | "email" | "tel" | "number" | "iban";
  inputTitle: string;
  placeholder: string;
  defaultValue?: string;
  inputName: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  pattern?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  errorMessage?: string;
  hidden?: boolean;
  value?: string | number | undefined;
  callback?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomInput: React.FC<InputProps> = ({
  inputType,
  inputTitle,
  placeholder,
  inputName,
  defaultValue,
  minLength,
  maxLength,
  required,
  errorMessage,
  pattern,
  disabled,
  value,
  hidden,
  min,
  max,
  callback,
}) => {
  const [text, setText] = useState("");
  if (defaultValue && (!value || (typeof value === "string" && !value.length))) {
    const event = {
      target: {
        name: inputName,
        value: defaultValue,
      },
    } as ChangeEvent<HTMLInputElement>;
    console.log("EMULATING CHANGE EVENT");
    defaultValue = undefined;
    customCallBack(event);
  }
  function customNumberCallBack(inputValue: ChangeEvent<HTMLInputElement>) {
    let valid = true;
    if (inputType === "iban") {
      const iban = electronicFormatIBAN(inputValue.target.value); // 'NL91ABNA0517164300'
      if (!isValidIBAN(iban ?? "")) {
        setText("Die IBAN ist nicht korrekt ");
        valid = false;
      }
    }
    if (min && max) {
      if (
        parseInt(inputValue.target.value) > max ||
        parseInt(inputValue.target.value) < min
      ) {
        setText(
          errorMessage
            ? errorMessage
            : "Der Wert muss kleiner " + min + " und größer " + max + " sein"
        );
        valid = false;
      }
    }
    if (minLength && maxLength) {
      if (
        inputValue.target.value.length < minLength ||
        inputValue.target.value.length > maxLength
      ) {
        setText(
          errorMessage
            ? errorMessage
            : "Dieses Feld ist ein Pflichtfeld und muss zwischen " + minLength + " und " + maxLength + " Zeichen lang sein."
        );
        valid = false;
      }
    }
  }

  function customCallBack(inputValue: ChangeEvent<HTMLInputElement>) {
    let valid = true;
    if (!inputValue.target.value.length) {
      if (callback) callback(inputValue);
      return;
    }
    //const regexPattern = /^[A-Za-z]+$/; // Example: Only letters allowed
    const regexObject = new RegExp(pattern ?? "");
    let isValidInput = regexObject.test(inputValue.target.value);
    if (!isValidInput) {
      setText(errorMessage ? errorMessage : "Falsch, richtig wäre: " + pattern);
    }
    if (isValidInput && valid) {
      setText("");
    }

    if (callback) callback(inputValue);
  }
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <input
        disabled={disabled}
        type={inputType === "iban" ? "text" : inputType}
        name={inputName}
        id={inputName.replaceAll(".", "")}
        placeholder={placeholder}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        defaultValue={defaultValue}
        hidden={hidden}
        required={required}
        value={(typeof value === "string" && value?.length) ? value : undefined}
        className={text.length ? "wrongTextInput" : ""}
        onBlur={customNumberCallBack}
        onChange={customCallBack}
      />
      <div
        className={
          text.length ? "wrongTextInputField" : "wrongTextInputField hidden"
        }
      >
        <p className="wrongTextInputField">{text}</p>
      </div>
      <input
        value={text.length === 0 ? "1" : ""}
        required
        hidden
        name="test"
      ></input>
    </>
  );
};

interface InputTurnusProps {
  inputTitle: string;
  inputName: string;
  required?: boolean;
  callback: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const InputTurnus: React.FC<InputTurnusProps> = ({
  inputTitle,
  inputName,
  required,
  callback,
}) => {
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <select name={inputName} id={inputName} required onChange={callback}>
        <option value={paymentInterval.yearly}>Jährlich</option>
        <option value={paymentInterval.halfYearly}>Halbjährlich</option>
        <option value={paymentInterval.quarterly}>Vierteljährlich</option>
        <option value={paymentInterval.monthly}>Monatlich</option>
      </select>
    </>
  );
};
interface InputAnredeProps {
  inputTitle: string;
  inputName: string;
  required?: boolean;
  value?: salutation;
  callback: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const InputAnrede: React.FC<InputAnredeProps> = ({
  inputTitle,
  inputName,
  required,
  callback,
  value,
}) => {
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <select
        name={inputName}
        id={inputName.replaceAll(".", "")}
        required={required}
        defaultValue={""}
        onChange={callback}
      >
        <option value="" disabled>
          Bitte wählen
        </option>
        <option value={salutation.Mr}>Herr</option>
        <option value={salutation.Mrs}>Frau</option>
        {/* <option value={salutation.various}>Divers</option> */}
      </select>
    </>
  );
};

interface InputDateProps {
  inputTitle: string;
  placeholder: string;
  inputName: string;
  min?: string;
  max?: string;
  required?: boolean;
  inputSubTitle?: string;
  value?: string;
  callback?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputDate: React.FC<InputDateProps> = ({
  inputTitle,
  placeholder,
  inputName,
  inputSubTitle,
  min,
  max,
  callback,
  required,
  value,
}) => {
  function customCallBack(event: ChangeEvent<HTMLInputElement>) {
    if (!(reference.current as any).validity.valid) {
      setText(true);
    } else {
      setText(false)
    }
    if (callback) {
      callback(event);
    }
  }
  let reference = useRef(null);
  let [text, setText] = useState(false)
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <input
        type="date"
        ref={reference}
        className="inputDateForm"
        name={inputName}
        id={inputName.replaceAll(".", "")}
        placeholder={placeholder}
        min={min}
        max={max}
        required={required}
        value={value}
        onChange={customCallBack}
      />
      <p className="inputSubTitle">{inputSubTitle}</p>
      {(text) && <b>Das Datum ist leider nicht gültig</b>}
    </>
  );
};

interface InputMietvertragProps {
  inputTitle: string;
  inputName: string;
  required?: boolean;
  value?: tenacyReason;
  callback: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const InputMietvertrag: React.FC<InputMietvertragProps> = ({
  inputTitle,
  inputName,
  required,
  callback,
  value,
}) => {
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <select
        name={inputName}
        id={inputName.replaceAll(".", "")}
        required={required}
        defaultValue={value}
        onChange={callback}
      >
        <option value={tenacyReason.convertTenacy}>
          Bestehende Mietkaution umwandeln
        </option>
        <option value={tenacyReason.newObject}>
          Mietkaution für neues Mietobjekt
        </option>
      </select>
    </>
  );
};

interface InputVersandProps {
  inputTitle: string;
  inputName: string;
  required?: boolean;
  value?: shippingOptions;
  callback: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const InputVersand: React.FC<InputVersandProps> = ({
  inputTitle,
  inputName,
  required,
  callback,
  value,
}) => {
  return (
    <>
      <label htmlFor={inputName}>{inputTitle}</label>
      <select
        name={inputName}
        id={inputName.replaceAll(".", "")}
        defaultValue={""}
        required={required}
        onChange={callback}
      >
        <option value="" disabled>Bitte wählen</option>
        <option value={shippingOptions.policyholder}>
          Versicherungsnehmer
        </option>
        <option value={shippingOptions.landlord}>Vermieter</option>
      </select>
    </>
  );
};
