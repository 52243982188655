import { useNavigate } from "react-router-dom";
import { CustomInput, InputDate, InputVersand } from "../input/Custominput";
import { pages, NavigationHeader } from "../navigation/navigator";
import { ChangeEvent } from "react";
import { paymentOptions } from "../interface";
import { ValidationHeader } from "../validation/validate";
import { activatedPages } from "../App";

interface Page4Interface {
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCheckBoxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  payment: paymentOptions;
  firstName: string;
  lastName: string;
  onUnlock: () => void;

  fireevent: (eventdata:string) => void;
  unlockedPages: activatedPages;
}
export const Page4: React.FC<Page4Interface> = ({
  handleInputChange,
  handleSelectChange,
  handleCheckBoxChange,
  firstName,
  fireevent,
  lastName,
  payment,
  onUnlock,
  unlockedPages,
}) => {
  const navigation = useNavigate();
  return (
    <ValidationHeader
      onUnlock={onUnlock}
      nextform={pages.page5}
      content={
        <section className="ekform-container">
          <NavigationHeader unlockedPages={unlockedPages}></NavigationHeader>
          <div id="section4">
            <div className="section">
              <h2>Bankverbindung</h2>
              <div className="ekform-row">
                <div className="ekform-block-4">
                  <CustomInput
                    callback={handleInputChange}
                    value={payment.iban}
                    inputType="iban"
                    inputTitle=""
                    inputName="payment.iban"
                    minLength={22}
                    maxLength={34}
                    required
                    placeholder="IBAN"
                  />
                </div>
                <div className="ekform-block-4" >
                  <CustomInput
                    value={payment.bic}
                    callback={handleInputChange}
                    inputType="text"
                    inputTitle=""
                    inputName="payment.bic"
                    minLength={8}
                    maxLength={11}
                    // defaultValue="XXXXXXXXXXX"
                    placeholder="BIC"
                  />
                </div>
                <div className="ekform-block-4" >
                  <CustomInput
                    callback={handleInputChange}
                    value={payment.creditInstitution}
                    inputType="text"
                    inputTitle=""
                    inputName="payment.creditInstitution"
                    minLength={2}
                    maxLength={30}
                    // defaultValue="XXXXXXXXXXX"
                    placeholder="Kreditinstitut"

                  />
                </div>
                <div className="ekform-block-12">
                  <fieldset>
                    <input
                      type="checkbox"
                      id="bank-sepa"
                      name="payment.allowsepa"
                      value="SEPA"
                      required
                      defaultChecked={payment.allowsepa}
                      onChange={handleCheckBoxChange}
                    />
                    <label className="sepalabel">
                      SEPA-Lastschriftmandat erteilen
                    </label>
                    <p>Ich ermächtige Sie, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von Ihnen auf mein Konto gezogenen Lastschriften einzulösen.</p>
                    <p>Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Vor der SEPA-Lastschrift informiert der Zahlungsempfänger über Abbuchungstermin und Betrag. </p>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="ekform-row buttonrow">
              <div className="ekform-block-2">
                <button
                  className="section3click backbutton"
                  onClick={() => {
                    window.parent.postMessage("complete", '*'); fireevent("kautionscheck.click.back");navigation("/step-3");
                  }}
                >
                  zurück
                </button>
              </div>
              <div className="ekform-block-6"></div>
              <div className="ekform-block-4">
                <button className="section5click">weiter</button>
              </div>
            </div>
          </div>
        </section>
      }
    ></ValidationHeader>
  );
};
